import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { AssessmentListDetail } from '../type';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentsReducer {
  status: RequestStatus;
  assessments: AssessmentListDetail[] | null;
}

const initialState: AssessmentsReducer = {
  status: RequestStatus.Init,
  assessments: null,
};

export const getAssessments = createAsyncThunk<AssessmentListDetail[], { account_id?: string } | undefined>(
  '/getAssessments',
  async (payload, thunkAPI) => {
    try {
      const url = payload?.account_id
        ? `${EndPoints.ASSESSMENT}?account_id=${payload.account_id}`
        : EndPoints.ASSESSMENT;

      const response: AxiosResponse<AssessmentListDetail[]> = await axiosV2Instance.get(url);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getAssessmentsSlice = createSlice({
  name: '/getAssessments',
  initialState,
  reducers: {
    resetAssessmentListState: (state) => {
      // Reset the state to the initial state
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssessments.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getAssessments.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.assessments = action.payload;
    });
    builder.addCase(getAssessments.rejected, (state) => {
      state.status = RequestStatus.Error;
    });
  },
});

export const { resetAssessmentListState } = getAssessmentsSlice.actions;

export default getAssessmentsSlice.reducer;