import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useState } from 'react';
import { getAssessments } from '../reducers/getAssessments';

interface assessmentsListProp {
  loading: boolean;
  error: unknown;
  getAllAssessments: (account_id?: string) => void;
}

const useGetAssessments = (): assessmentsListProp => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();

  const getAllAssessments = async (account_id?: string) => {
    try {
      const payload = account_id ? { account_id } : undefined;
      const resultAction = await dispatch(getAssessments(payload));
      if (getAssessments.fulfilled.match(resultAction)) {
        setLoading(false);
      }
      return resultAction;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false)
    }
  };

  return { loading, error, getAllAssessments };
};

export default useGetAssessments;
