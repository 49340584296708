import React, { useEffect } from 'react';
import { Box, Chip, CircularProgress, Grid, Tooltip } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import AccountSettings from '../AccountSettings/AccountSettings';
import { useParams } from 'react-router';
import ClientOverview from '../ClientOverview/ClientOverview';
import AccountEvaluation from '../AccountEvaluation/AccountEvaluation';
import { SaveButton, CancelButton } from '../CreateClient/CreateClient.styles';
import { useNavigate } from 'react-router-dom';
import useGetClient from '../hooks/useGetClient';
import { useAppSelector } from '../../../redux/store';
import { AccountDetails } from '../types';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {
  Typography13px400,
  Typography14px600,
} from '../../../styles/Global.styles';
import { useBranding } from '../../Branding/reducers/getBranding';
import { FeatureFlags } from '../../Branding/type'
import AssessmentList from '../../Assessments/AssessmentList/components/AssessmentList';
import { resetAssessmentListState } from '../../Assessments/reducers/getAssessments';
import { useDispatch } from 'react-redux';

const AccountInfoCard = (accountData: AccountDetails) => {
  return (
    <Grid container sx={{ display: 'flex', padding: '10px 30px' }}>
      <Grid item sm={2.5}>
        <Tooltip placement="top-start" title={accountData.name}>
          <Typography14px600
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {accountData.name}
          </Typography14px600>
        </Tooltip>
      </Grid>
      <Grid item sm={3}>
        <Tooltip placement="top-start" title={accountData.email}>
          <Typography13px400
            sx={{
              color: '#B5B5C3',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {accountData.email}
          </Typography13px400>
        </Tooltip>
      </Grid>
      <Grid item sm={2.5}>
        <Typography13px400
          sx={{
            color: '#B5B5C3',
          }}
        >
          {accountData.city || <HorizontalRuleIcon />}
        </Typography13px400>
      </Grid>
      <Grid item sm={1}>
        {accountData.is_active ? (
          <Chip
            sx={{
              borderRadius: '6px',
              background: 'rgba(244, 255, 243, 1)',
              color: '#000',
              fontWeight: '600',
              textAlign: 'center',
            }}
            label={'Active'}
            color="success"
          />
        ) : (
          <Chip
            sx={{
              borderRadius: '6px',
              background: 'rgba(255, 226, 229, 1)',
              color: '#000',
              fontWeight: '600',
              textAlign: 'center',
            }}
            label={'Inactive'}
          />
        )}
      </Grid>
    </Grid>
  );
};

const AccountDetailView = () => {
  const [value, setValue] = React.useState('1');
  const [brandingLoading, setBrandingLoading] = React.useState(true);
  const [useLegacyEndpoints, setUseLegacyEndpoints] = React.useState<boolean | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const branding = useBranding();
  const dispatch = useDispatch();

  const { loading, error, getAccountById } = useGetClient();
  const data = useAppSelector((state) => {
    return state.allClientReducer.getClientReducer.client;
  });

  useEffect(() => {
    if (branding !== null) {
      // When branding data is fetched, update the loading state and the useLegacyEndpoints flag
      setUseLegacyEndpoints(branding?.active_feature_flags?.[FeatureFlags.LEGACY_V1_ENDPOINTS] === true);
      setBrandingLoading(false);
    }
  }, [branding]);

  useEffect(() => {
    if (id) {
      getAccountById(id);
      dispatch(resetAssessmentListState());
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      {error || loading || !data ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '20px',
              paddingBottom: '20px',
            }}
          >
            <SaveButton
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </SaveButton>
            <SaveButton
              onClick={() => {
                navigate(`/edit-account/${id as string}`);
              }}
            >
              Edit
            </SaveButton>
          </Box>
          <AccountInfoCard {...data} />
          <TabContext value={value}>
            <Box>
              <TabList
                sx={{
                  '& .MuiTab-root': {
                    '&.Mui-selected': {
                      color: '#00DB99',
                    },
                    textTransform: 'none',
                  },
                }}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
                aria-label="lab API tabs example"
              >
                <Tab label="Overview" value="1" />
                <Tab label={branding?.evaluation_property_name_translation ?? "Evaluation"} value="2" />
                <Tab label="Settings" value="3" />
              </TabList>
            </Box>
            <TabPanel sx={{ padding: '0' }} value="1">
              <ClientOverview id={data} />
            </TabPanel>
            <TabPanel sx={{ padding: '0' }} value="2">
              {brandingLoading || !branding ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  minHeight="200px"
                >
                  <CircularProgress />
                </Box>
              ) : useLegacyEndpoints ? (
                <AccountEvaluation id={id || ''} />
              ) : (
                <AssessmentList
                  displayEmployerOfChoiceIntakeFormLink={false}
                  displayCreateAssessmentButton={false}
                  accountId={id}
                />
              )}
            </TabPanel>
            <TabPanel sx={{ padding: '0' }} value="3">
              <AccountSettings id={id || ''} accountExecutiveNameTranslation={branding?.account_executive_name_translation as string} />
            </TabPanel>
          </TabContext>
        </>
      )}
    </Box>
  );
};

export default AccountDetailView;
